export const bots = [
    { id: 1, name: "Leyes", avatar: "📜", locked: false, prompts: [
      "Explica la ley de protección al consumidor",
      "¿Cuáles son los derechos laborales básicos?",
      "Resumen de la ley de tránsito"
    ]},
    { id: 2, name: "Doctrina", avatar: "✍🏻", locked: false, prompts: [
      "Explica la teoría del contrato social",
      "¿Qué es el positivismo jurídico?",
      "Principales corrientes del derecho natural"
    ]},
    { id: 3, name: "Jurisprudencia", avatar: "⚖️", locked: true, prompts: [
      "Casos emblemáticos sobre libertad de expresión",
      "Jurisprudencia reciente en materia de derecho ambiental",
      "Interpretación jurisprudencial del debido proceso"
    ]},
    { id: 4, name: "Jurisprudencia Administrativa", avatar: "📚", locked: true, prompts: [
      "Dictámenes de la Contraloría sobre contratación pública",
      "Jurisprudencia administrativa en materia de función pública",
      "Interpretaciones administrativas de la ley de transparencia"
    ]},
  ];