import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { ArrowDownCircle } from "lucide-react";
import logoImageWhite from "../../assets/logo-white.png";
import Navbar from "./Navbar";
import Description from "./Description";
import Features from "./Features";
import Footer from "./Footer";
import FAQSection from "./FAQSection";
import Pricing from "./Pricing";
import { postStats } from "../../api/axios";

import { useNavigate } from "react-router-dom";

export default function LandingPage() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [showNavbar, setShowNavbar] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    const handleScroll = () => {
      setShowNavbar(window.scrollY > 100);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const calculateRotation = (x, y) => {
    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;
    const rotateX = (y - centerY) / 50;
    const rotateY = (centerX - x) / 50;
    return `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
  };

  const handleNavigatelogin = () => {
    postStats("landing-page", "start-now");
    navigate("/suscripcion");
  };

  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Navbar isVisible={showNavbar} />
      <section className="relative h-screen overflow-hidden bg-gradient-to-br from-[#2A3E6D] via-[#3085C4] to-[#40B3E8]">
        <div
          className="absolute inset-0 bg-cover bg-center opacity-20"
          style={{
            transform: calculateRotation(mousePosition.x, mousePosition.y),
            transition: "transform 0.1s ease-out",
          }}
        ></div>
        <div className="container relative mx-auto flex h-full items-center px-4">
          <div className="grid gap-8 lg:grid-cols-2">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="flex flex-col justify-center space-y-4"
            >
              <div className="w-48 mb-2">
                <img
                  src={logoImageWhite}
                  alt="Lexia Logo"
                  className="w-full h-auto object-contain"
                />
              </div>
              <h1 className="text-4xl font-extrabold leading-tight tracking-tighter text-white sm:text-5xl md:text-6xl lg:text-7xl">
                El asistente <br />
                <span className="bg-gradient-to-r from-[#40B3E8] to-white bg-clip-text text-transparent">
                  de los Abogados
                </span>
              </h1>
              <p className="max-w-[600px] text-xl text-white sm:text-2xl">
                La IA que transforma el acceso y análisis de leyes chilenas,
                simplificando la comprensión y toma de decisiones.
              </p>
              <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="inline-flex h-12 items-center justify-center rounded-md bg-white px-6 font-medium text-[#4058A0] shadow-lg transition-colors hover:bg-[#40B3E8] hover:text-white"
                  onClick={() => handleNavigatelogin()}
                >
                  Comienza Ahora
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="inline-flex h-12 items-center justify-center rounded-md border-2 border-white bg-transparent px-6 font-medium text-white transition-colors hover:bg-white hover:text-[#4058A0]"
                  onClick={() => handleScrollToSection("description-section")}
                >
                  Aprende Más
                </motion.button>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="relative hidden lg:block"
            >
              <div className="absolute -left-4 top-1/2 h-64 w-64 -translate-y-1/2 rounded-full bg-[#40B3E8] opacity-30 blur-3xl"></div>
              <div className="absolute -right-4 top-1/2 h-64 w-64 -translate-y-1/2 rounded-full bg-[#2A3E6D] opacity-30 blur-3xl"></div>
            </motion.div>
          </div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.5 }}
          className="absolute bottom-8 left-1/2 -translate-x-1/2"
        >
          <motion.div
            animate={{ y: [0, 10, 0] }}
            transition={{ repeat: Infinity, duration: 1.5, ease: "easeInOut" }}
            onClick={() => handleScrollToSection("description-section")}
          >
            <ArrowDownCircle className="h-10 w-10 text-white opacity-75" />
          </motion.div>
        </motion.div>
      </section>
      <Description />
      <Features />
      <Pricing />
      <FAQSection/>
      <Footer />
    </>
  );
}
