import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  handlePrompt,
  getPrompts,
  getPrompt,
  getPaymentInfoUser,
} from "../../api/axios";
import ReactMarkdown from "react-markdown";
import { Menu, X, AlertTriangle, Lock, CreditCard } from "lucide-react";

import useLogout from "../../hooks/useLogout";
import useAuth from "../../hooks/useAuth";
import { SidebarContent } from "./Chat/SidebarContent";
import { bots } from "./Chat/bots";

export default function Component() {
  const [selectedBot, setSelectedBot] = useState(bots[0]);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const dropdownRef = useRef(null);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const textareaRef = useRef(null);
  const [showWarning, setShowWarning] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPaying, setIsPaying] = useState(true);

  const logout = useLogout();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const token = auth?.accessToken;

  const handleBotChange = (bot) => {
    if (!bot.locked) {
      setSelectedBot(bot);
    }
  };

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  const fetchConversations = async () => {
    if (!token) return;
    try {
      const response = await getPrompts(token);
      console.log("Conversations:", response.data);
      setConversations(response.data || []);
    } catch (error) {
      console.error("Error fetching conversations:", error);
      setConversations([]);
    }
  };

  const fetchPaymentInfo = async () => {
    if (!token) return;
    try {
      const response = await getPaymentInfoUser(token);
      setIsPaying(response.data.isPaying);
    } catch (error) {
      console.error("Error fetching payment info:", error);
      setIsPaying(false);
    }
  };

  useEffect(() => {
    if (auth) {
      setShortName(auth.shortName || "");
      setName(auth.name || "");
      fetchConversations();
      fetchPaymentInfo();
    }
  }, [auth]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    if (!token) {
      console.error("No token available");
      setErrorMessage(
        "No se encontró un token de autenticación. Por favor, inicia sesión de nuevo."
      );
      return;
    }

    if (!isPaying) {
      setErrorMessage(
        "Por favor, agrega un método de pago para continuar usando el chat."
      );
      return;
    }

    const isNewConversation = !selectedConversation && messages.length === 0;

    if (
      !isNewConversation &&
      (!selectedConversation || !selectedConversation._id)
    ) {
      console.error("Invalid conversation selected");
      setErrorMessage("La conversación seleccionada no es válida.");
      return;
    }

    const newUserMessage = { sender: "user", content: input };
    setMessages((prevMessages) => [...prevMessages, newUserMessage]);
    setInput("");
    setIsLoading(true);
    setShowWarning(false);
    setErrorMessage("");

    try {
      const response = await handlePrompt(
        token,
        input,
        selectedBot.name.toLowerCase(),
        isNewConversation,
        selectedConversation?._id
      );
      console.log("Prompt response:", response.data);
      console.log(response.status);
      if (response.status === 200) {
        const newAssistantMessage = {
          sender: "assistant",
          content: response.data.message,
        };
        setMessages((prevMessages) => [...prevMessages, newAssistantMessage]);

        if (isNewConversation && response.data.threadId) {
          setSelectedConversation({ _id: response.data.threadId });
        }

        fetchConversations();
      } else if (response.status === 429) {
        setShowWarning(true);
        const warningMessage = {
          sender: "system",
          content:
            "No se pueden hacer más llamados este mes. Por favor, actualiza tu plan.",
        };
        setMessages((prevMessages) => [...prevMessages, warningMessage]);
      } else {
        const errorMessage =
          response.data.message || "Ocurrió un error en el servidor.";
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "system", content: errorMessage },
        ]);
      }
    } catch (error) {
      console.error("Error calling handlePrompt:", error);
      if (error.response) {
        const status = error.response.status;
        const errorMsg = error.response.data.message || "Ocurrió un error.";
        console.error("Error response:", error.response);

        if (status === 400 || status === 404) {
          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: "system", content: errorMsg },
          ]);
        } else if (status === 429) {
          setShowWarning(true);
          const warningMessage = {
            sender: "system",
            content:
              "No se pueden hacer más llamados este mes. Por favor, actualiza tu plan.",
          };
          setMessages((prevMessages) => [...prevMessages, warningMessage]);
        } else {
          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: "system", content: "Ocurrió un error en el servidor." },
          ]);
        }
      } else {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "system",
            content: "Error de red. Por favor, verifica tu conexión.",
          },
        ]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleConversationSelect = async (conversation) => {
    if (!token) return;
    try {
      const response = await getPrompt(token, conversation._id);
      setSelectedConversation(response.data);
      setMessages(response.data.messages || []);
      setSelectedBot(
        bots.find((bot) => bot.name.toLowerCase() === response.data.botType) ||
          bots[0]
      );
      setIsSidebarOpen(false);
    } catch (error) {
      console.error("Error fetching conversation details:", error);
      if (error.response) {
        const status = error.response.status;
        const errorMsg = error.response.data.message || "Ocurrió un error.";

        if (status === 400 || status === 404) {
          setErrorMessage(errorMsg);
        } else {
          setErrorMessage("Ocurrió un error al cargar la conversación.");
        }
      } else {
        setErrorMessage("Error de red. Por favor, verifica tu conexión.");
      }
    }
  };

  const handleNewChat = () => {
    setSelectedConversation(null);
    setMessages([]);
    setIsSidebarOpen(false);
    setShowWarning(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setInput(input + "\n");
    } else if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [input]);

  const handlePromptClick = (prompt) => {
    setInput(prompt);
    handleSubmit({ preventDefault: () => {} });
  };

  const handleAddPayment = () => {
    // Implement the logic to navigate to the payment page or open a payment modal
    navigate("/add-payment");
  };

  return (
    <div className="flex h-screen bg-gray-50 font-sans">
      {/* Sidebar for larger screens */}
      <div
        className={`w-64 bg-white flex-shrink-0 border-r border-gray-200 md:flex flex-col ${
          isSidebarOpen ? "flex" : "hidden"
        }`}
      >
        <SidebarContent
          name={name}
          shortName={shortName}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          dropdownRef={dropdownRef}
          handleNewChat={handleNewChat}
          conversations={conversations}
          selectedConversation={selectedConversation}
          handleConversationSelect={handleConversationSelect}
          navigate={navigate}
          handleLogout={handleLogout}
        />
      </div>

      {/* Main Chat Interface */}
      <div className="flex-grow flex flex-col overflow-hidden">
        <header className="bg-white border-b border-gray-200 p-4 flex items-center justify-between">
          <button
            onClick={toggleSidebar}
            className="md:hidden text-gray-500 hover:text-gray-600"
          >
            {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
          <div className="flex space-x-2 overflow-x-auto">
            {bots.map((bot) => (
              <button
                key={bot.id}
                onClick={() => handleBotChange(bot)}
                className={`flex-shrink-0 px-4 py-2 rounded-md transition duration-150 ease-in-out relative ${
                  selectedBot.id === bot.id
                    ? "bg-gradient-to-r from-[#40B3E8] to-[#4058A0] text-white font-semibold"
                    : bot.locked
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
                disabled={bot.locked}
              >
                <span className="mr-2">{bot.avatar}</span> {bot.name}
                {bot.locked && (
                  <Lock className="absolute top-1 right-1 h-4 w-4 text-gray-500" />
                )}
                {bot.locked && (
                  <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 mb-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                    Próximamente
                  </span>
                )}
              </button>
            ))}
          </div>
        </header>
        <div className="flex-grow overflow-auto p-4 bg-gray-50">
          {showWarning && (
            <div
              className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4"
              role="alert"
            >
              <div className="flex items-center">
                <AlertTriangle className="h-5 w-5 mr-2" />
                <p>
                  No se pueden hacer más llamados este mes. Por favor, actualiza
                  tu plan.
                </p>
              </div>
            </div>
          )}
          {errorMessage && (
            <div
              className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4"
              role="alert"
            >
              <p>{errorMessage}</p>
            </div>
          )}
          {!isPaying && (
            <div
              className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4"
              role="alert"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <CreditCard className="h-5 w-5 mr-2" />
                  <p>
                    Por favor, agrega un método de pago para continuar usando el
                    chat.
                  </p>
                </div>
                <button
                  onClick={handleAddPayment}
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-150 ease-in-out"
                >
                  Agregar método de pago
                </button>
              </div>
            </div>
          )}
          {messages.length === 0 ? (
            <div className="h-full flex flex-col items-center justify-center">
              <img
                src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/logo-t9HBPCQ1PXdn4ZB40FjUBL9jiymOpJ.png"
                alt="Lexia Logo"
                className="w-64 h-auto mb-8 opacity-20"
              />
              <div className="grid grid-cols-1 gap-4 w-full max-w-md">
                {selectedBot.prompts.map((prompt, index) => (
                  <button
                    key={index}
                    onClick={() => handlePromptClick(prompt)}
                    className="p-2 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 text-left text-gray-700 hover:text-gray-900"
                    disabled={!isPaying}
                  >
                    {prompt}
                  </button>
                ))}
              </div>
            </div>
          ) : (
            messages.map((message, index) => (
              <div
                key={index}
                className={`flex items-start space-x-4 mb-4 ${
                  message.sender === "assistant"
                    ? "justify-start"
                    : message.sender === "user"
                    ? "justify-end"
                    : "justify-center"
                }`}
              >
                {message.sender === "assistant" && (
                  <div className="w-8 h-8 rounded-full bg-gradient-to-r from-[#40B3E8] to-[#4058A0] flex items-center justify-center text-white flex-shrink-0">
                    {selectedBot.avatar}
                  </div>
                )}
                <div
                  className={`rounded-lg p-3 max-w-[70%] ${
                    message.sender === "assistant"
                      ? "bg-white text-gray-800 shadow-sm"
                      : message.sender === "user"
                      ? "bg-gradient-to-r from-[#40B3E8] to-[#4058A0] text-white"
                      : "bg-yellow-100 text-yellow-700"
                  }`}
                >
                  <ReactMarkdown>{message.content}</ReactMarkdown>
                </div>
                {message.sender === "user" && (
                  <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center flex-shrink-0">
                    <svg
                      className="h-4 w-4 text-gray-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                      />
                    </svg>
                  </div>
                )}
              </div>
            ))
          )}
          {isLoading && (
            <div className="flex items-center justify-start space-x-4 mb-4">
              <div className="w-8 h-8 rounded-full bg-gradient-to-r from-[#40B3E8] to-[#4058A0] flex items-center justify-center text-white flex-shrink-0">
                {selectedBot.avatar}
              </div>
              <div className="bg-white rounded-lg p-3 shadow-sm">
                <div className="flex space-x-2">
                  <div className="w-3 h-3 bg-[#40B3E8] rounded-full animate-bounce"></div>
                  <div
                    className="w-3 h-3 bg-[#40B3E8] rounded-full animate-bounce"
                    style={{ animationDelay: "0.2s" }}
                  ></div>
                  <div
                    className="w-3 h-3 bg-[#40B3E8] rounded-full animate-bounce"
                    style={{ animationDelay: "0.4s" }}
                  ></div>
                </div>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <footer className="bg-white border-t border-gray-200 p-4">
          <form onSubmit={handleSubmit} className="flex items-center space-x-2">
            <textarea
              ref={textareaRef}
              placeholder="Escribe tu mensaje..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              rows={1}
              disabled={isLoading || !isPaying}
              style={{ minHeight: "40px", maxHeight: "200px" }}
              className="flex-grow px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#40B3E8] focus:border-transparent resize-none overflow-hidden"
            />
            <button
              type="submit"
              className="px-4 py-2 bg-gradient-to-r from-[#40B3E8] to-[#4058A0] text-white rounded-md hover:from-[#3BA2D2] hover:to-[#3A4F91] focus:outline-none focus:ring-2 focus:ring-[#40B3E8] focus:ring-offset-2 transition duration-150 ease-in-out"
              disabled={isLoading || !isPaying}
            >
              <svg
                className="h-4 w-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                />
              </svg>
            </button>
          </form>
        </footer>
      </div>
    </div>
  );
}
