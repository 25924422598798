import React from "react";
import { ToggleRight, ToggleLeft, Trash2 } from "lucide-react";

export default function UserTable({ users, onDeleteUser, onToggleUserStatus, authEmail }) {
  const getRoleName = (roleObject) => {
    const roleKey = Object.keys(roleObject)[0];
    const roleValue = roleObject[roleKey];

    if (roleValue === 300) {
      return "Admin";
    } else if (roleValue === 200) {
      return "Moderador";
    } else if (roleValue === 100) {
      return "Usuario";
    } else {
      return "Desconocido";
    }
  };

  return (
    <div className="overflow-x-auto bg-white rounded-lg shadow">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Nombre
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Email
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Role
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Plan
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Tokens
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Estado
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Acciones
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {users.map((user) => (
            <tr key={user.id}>
              <td className="px-6 py-4 whitespace-nowrap">{user.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
              <td className="px-6 py-4 whitespace-nowrap">{getRoleName(user.roles)}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {user.plan === 1000 ? "Ilimitado" : user.plan === 100 ? "Demo" : "Plan Básico"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {user.tokensUsed || 0} / {user.totalTokens || 1000}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span
                  className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    user.isActive ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
                  }`}
                >
                  {user.isActive ? "Activo" : "Inactivo"}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                {user.email !== authEmail && (
                  <>
                    <button
                      onClick={() => onToggleUserStatus(user._id)}
                      className="text-[#40B3E8] hover:text-[#3BA2D2] mr-2"
                      aria-label={`Toggle status for ${user.name}`}
                    >
                      {user.isActive ? (
                        <ToggleRight className="h-5 w-5" />
                      ) : (
                        <ToggleLeft className="h-5 w-5" />
                      )}
                    </button>
                    <button
                      onClick={() => onDeleteUser(user._id)}
                      className="text-red-600 hover:text-red-900"
                      aria-label={`Delete ${user.name}`}
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}